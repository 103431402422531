/* @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap'); */

/* .gradient-background {
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, rgba(0,0,0,0.8), rgba(0,0,0,0.2));
    backdrop-filter: blur(10px);
} */
/* .gradient-background {
    background-image: url("/wave.svg");
  background-repeat: no-repeat;
  background-position: center;
} */

@tailwind base;
@tailwind components;
@tailwind utilities;


